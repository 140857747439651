import React from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";

import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import {
  InfoDietTimeframe,
  useInfoDiet,
  SourceDetailsTable,
  BiasRatings,
  CategorySource,
} from "features/User";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";

export default function InfoDiet() {
  const {
    infoDietTimeframe,
    handleTimeframeChange,
    infoDietEndDate,
    infoDietStartDate,
    handleEndDateChange,
    handleStartDateChange,
  } = useInfoDiet();
  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack
        alignItems="stretch"
        direction={{ xs: "column", md: "row" }}
        gap={2}
        mt={3}
      >
        <Stack
          direction="column"
          sx={{
            flex: { xs: "unset", md: 7 },
          }}
        >
          <Stack direction="row" alignItems="flex-start">
            <PageTitle
              sx={{
                flex: 6,
                mt: 0,
              }}
            >
              Info Diet
            </PageTitle>
            <InfoDietTimeframe
              timeframeValue={infoDietTimeframe}
              setTimeframeValue={handleTimeframeChange}
              startDateValue={infoDietStartDate}
              setStartDateValue={handleStartDateChange}
              endDateValue={infoDietEndDate}
              setEndDateValue={handleEndDateChange}
              sx={{
                flex: 6,
              }}
            />
          </Stack>
          <Typography variant="body2">
            Your Info Diet is a summary of your recent research, grouped by
            source, media bias rating, and category. Your Info Diet data is
            updated daily.
          </Typography>
          <Alert severity="warning">
            Info Diet data can be negatively impacted by ad-blockers, resulting
            in an incomplete display of your data.
          </Alert>
          <SourceDetailsTable />
        </Stack>
        <Stack
          direction="column"
          sx={{
            flex: { xs: "unset", md: 5 },
          }}
        >
          <BiasRatings />
          <CategorySource />
        </Stack>
      </Stack>
      <Footer />
    </Box>
  );
}
