import {
  Card,
  Typography,
  CardMedia,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
  Grid,
  Chip,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import React, { useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { ReactComponent as CollectionsLogoHover } from "assets/icons/bookmark-hover-icon.svg";
import { HiOutlineLockOpen, HiOutlineLockClosed } from "react-icons/hi2";

import { useDispatch } from "react-redux";
import {
  setCollectionId,
  setCollectionSourceId,
  setEntityName,
  setIsCitationAvailable,
  setIsSidebarOpen,
  setIsSourceSidebarOpen,
  setIsSummaryAvailable,
  setLevel,
  setOthersNeeded,
  setReadingLevelAvailable,
  setSelectedBookmarkOption,
  setSourceUrl,
  setSummaryInfoData,
} from "context/features/dataSlice";
import defaultBackground from "assets/images/image-placeholder.png";
import { VscLock } from "react-icons/vsc";
import NewsguardScore from "components/base/NewsguardScore";
import AllSidesRating from "components/base/all-sides/AllSidesRating";
import { logEvent } from "services/amplitude.service.js";
import NewsCard from "features/Pulse/components/news-card";
import CharityNavigatorScore from "components/base/CharityNavigatorScore";
import CheckIcon from "@mui/icons-material/Check";
import { useIsTab } from "hooks/is-tab.hook";
import getRefreshTOkenWashingtonPost from "hooks/use-washinghton-token";

export const CategoryCard = ({ data, cardRef, category, query }) => {
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const isTab = useIsTab();
  const dispatch = useDispatch();

  const imageUrl = data?.openGraphImage?.contentUrl
    ? data?.openGraphImage?.contentUrl
    : defaultBackground;

  const openSourceSidebar = () => {
    logEvent("click_card_header", {
      host: data.hostName,
      entity: data.entityName,
    });
    dispatch(setOthersNeeded(true));
    dispatch(setIsSourceSidebarOpen(true));
    dispatch(setSourceUrl(data.hostName));
    dispatch(setEntityName(data.entityName));
  };

  const newUrl = (() => {
    let updatedUrl = data.url;

    if (updatedUrl.includes("foreignpolicy.com")) {
      updatedUrl += "?auth=sooth";
    }

    return updatedUrl;
  })();


  const openSidebar = () => {
    logEvent("open_bookmark_popup", {
      url: newUrl,
      query: query,
      entity: data.entityName,
      category: category,
      type: "individual-category",
      mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
      score: data.score ? data.score : "NA",
    });
    dispatch(setSelectedBookmarkOption("notes"));
    dispatch(setLevel(""));
    setIsHovered(false);
    dispatch(setCollectionSourceId(data.collectionSourceId));
    dispatch(
      setSummaryInfoData({
        publisher: data.entityName,
        title: data.name,
        published_date: data.datePublishedDisplayText,
        url: data.url,
      })
    );
    dispatch(setCollectionId(data.collectionId));
    dispatch(setIsCitationAvailable(data.isCitationAvailable));
    dispatch(setIsSummaryAvailable(data.isSummaryAvailable));
    dispatch(setReadingLevelAvailable(data.isReadingLevelAvailable));
    dispatch(setIsSidebarOpen(true));
  };

  const handleTitleClick = async () => {
    let titleUrl = newUrl;
    if (newUrl.includes("washingtonpost.com")){
      titleUrl = await getRefreshTOkenWashingtonPost(newUrl);
    } 
    window.open(titleUrl, "_blank");

    logEvent("click_resultset_title", {
      url: newUrl,
      query: query,
      entity: data.entityName,
      category: category,
      mediabiasrating: data.mediaBiasRating ? data.mediaBiasRating : "NA",
    });
  };

  const BookmarkOptions = () => {
    return (
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          openSidebar();
          setIsHovered(false);
        }}
        sx={{
          cursor: "pointer",
          pl: 1,
          pr: 1,
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "secondary.main",
          },
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
      >
        {data.collectionSourceId ? (
          <CollectionsLogo
            onClick={openSidebar}
            alt="Collections"
            style={{
              width: 18,
              height: 18,
              fill: "blue",
              color: "blue",
            }}
          />
        ) : (
          <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              width: 17,
              height: 17,
            }}
          >
            {isHovered && (
              <CollectionsLogoHover
                onClick={openSidebar}
                alt="Collections"
                style={{
                  width: 17,
                  height: 17,
                  fill: "blue",
                  color: "blue",
                }}
              />
            )}
          </Box>
        )}
        <IconButton
          aria-label="more"
          sx={{
            p: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Box
      ref={cardRef}
      container
      spacing={2}
      sx={{
        display: "grid",
        gridTemplateAreas: `
      'sourceSidebar sourceSidebar sourceSidebar'
      'content content content'
      'divider divider divider'`,
        gridTemplateColumns: "3fr 0.2fr 1fr",
        gridTemplateRows: "auto 1fr auto",
        padding: 0,
        mb: 1,
      }}
    >
      {/* Sidebar Section */}
      <Box
        item
        sx={{
          gridArea: "sourceSidebar",
          mb: 2,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": { backgroundColor: "secondary.main" },
          borderRadius: 1,
          py: 0.4,
          px: 1,
        }}
        onClick={openSourceSidebar}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", color: "rgb(126,127,126)" }}
        >
          {data.entityName}
        </Typography>

        <Box
          sx={{
            ml: "auto",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            width: { md: "12vw", lg: "10vw" },
            minWidth: "160px",
          }}
        >
          {data.hasOpenAccess && (
            <Tooltip title="100% of this publisher's content is Open Access">
              <Chip
                sx={{
                  p: 1,
                  height: "24px",
                  fontSize: "12px",
                  "& .MuiChip-icon": {
                    margin: 0,
                  },
                  "& .MuiChip-label": {
                    display: isTab ? "none" : "inline",
                  },
                }}
                icon={<HiOutlineLockOpen />}
                label={isTab ? "" : "Open Access"}
              />
            </Tooltip>
          )}
          {data.mediaBiasRating && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: { md: "8vw", lg: "7vw" },
                minWidth: "80px",
                ml: 1,
              }}
            >
              <AllSidesRating activeCategory={data.mediaBiasRating} />
            </Box>
          )}
          {data.score && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: { md: "5vw", lg: "3vw" },
                minWidth: "50px",
              }}
            >
              <NewsguardScore score={Number(data.score)} size="small" />
            </Box>
          )}

          {data.charityNavigatorScore &&
            category !== "Academia & Scholarly Resources" &&
            !data.score &&
            !data.mediaBiasRating && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: { md: "5vw", lg: "3vw" },
                  minWidth: "50px",
                }}
              >
                <CharityNavigatorScore
                  score={data.charityNavigatorScore}
                  size="small"
                />
              </Box>
            )}
        </Box>
      </Box>

      {/* Content Section: Title, Description, and Image */}
      <Box
        item
        sx={{
          gridArea: "content",
          display: "flex",
          gap: 2,
          alignItems: "flex-start",
          pl: 1,
        }}
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1 }}>
          {/* Title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Typography
              onClick={() => handleTitleClick()}
              variant="body4"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                lineHeight: "1.5em",
                maxWidth: "90%",
                flexShrink: 0,
                cursor: "pointer",
                "&:hover": {
                  color: "rgb(18,89,151)",
                },
              }}
            >
              {data.name}
            </Typography>
            {data.hasPayWall && data.isPartner ? (
              <Tooltip title="We removed the paywall for you!">
                <Box sx={{ cursor: "pointer", mt: "0.2em" }}>
                  <HiOutlineLockOpen />
                </Box>
              </Tooltip>
            ) : (
              data.hasPayWall && (
                <Tooltip
                  placement="bottom-start"
                  title="This source may limit your access without a subscription"
                >
                  <Box sx={{ cursor: "pointer", mt: "0.2em" }}>
                    <HiOutlineLockClosed />
                  </Box>
                </Tooltip>
              )
            )}
          </Box>

          {/* Description and Bookmark Options in a Row */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: { xs: 3, sm: 5 },
                WebkitBoxOrient: "vertical",
                maxHeight: { xs: "6vh", sm: "10vh" },
                lineHeight: { xs: "2vh", sm: "2vh" },
                width: "90%",
              }}
            >
              {data.datePublishedDisplayText &&
                `${data.datePublishedDisplayText} - `}
              {data.snippet}
            </Typography>

            {/* Bookmark Options */}
          </Stack>
        </Box>

        {/* Image Section */}
        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            width: "10vw",
            pr: 1,
          }}
        >
          <Box>
            <CardMedia
              component="img"
              sx={{ height: "12vh", borderRadius: 1 }}
              image={imageUrl}
              alt="Article Image"
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <BookmarkOptions />
          </Box>
        </Box>
      </Box>

      {/* Divider Section */}
      <Box
        item
        sx={{
          gridArea: "divider",
          mt: 1,
          paddingLeft: 0,
        }}
      >
        <Divider />
      </Box>
    </Box>
  );
};
