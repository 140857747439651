import { map, orderBy } from "lodash";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  AddOutlined,
  Close,
  CopyAllOutlined,
  DownloadOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  Link as MuiLink,
} from "@mui/material";
import {
  useGroup,
  AutoJoinDomain,
  useGroupControls,
  GroupCodeListItem,
  GroupDomain,
  AddGroupUserModal,
  GroupUserBulkUpload,
} from "features/Group";
import { FeatureHelpVideo } from "features/Support";
import { toastError } from "utils/toast.util";
import { downloadFile } from "utils/html.util";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";
import { CSVLink } from "react-csv";
import { useIsMobile } from "hooks/is-mobile.hook";

export default function AddUsersPage() {
  const { palette } = useTheme();
  const isMobile = useIsMobile();

  const { group, groupId, isGroupLoading } = useGroup();
  const {
    groupCodes,
    createGroupCode,
    isGroupControlsLoading,
    groupDomains,
    getGroupDomains,
  } = useGroupControls();

  const [verifyModal, setVerifyModal] = useState(null);
  const [showNew, setShowNew] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);

  const refreshTimer = useRef(null);

  const templateRef = useRef(null);

  function handleAddUserClose() {
    setAddUserModal(false);
  }

  async function handleCreateGroupCode() {
    const newCode = await createGroupCode();
    toast.success(`Created new Group Code: '${newCode.code}'`);
  }

  async function handleCopyVerificationCode(code) {
    try {
      await navigator.clipboard.writeText(code);
      toast.success(`verification code '${code}' was copied to clipboard.`);
    } catch (error) {
      toastError(`Unable to copy code '${code}' to clipboard.`);
    }
  }

  const planTypeLevel = useMemo(() => {
    return group != null ? group.planType.split("-")[1].toLowerCase() : "";
  }, [group]);

  const licenseLevels = useMemo(() => {
    return group != null && group.licenseLevels != null
      ? group.licenseLevels.map((level) => level.key).join(", ")
      : "";
  }, [group]);

  const templateData = useMemo(() => {
    if (group == null || group.licenseLevels == null) {
      return [];
    }

    const emails = ["jane@school.org", "john@school.org", "sally@school.org"];

    const data = [];

    const rows =
      group.licenseLevels.length < 2 ? 2 : group.licenseLevels.length;
    for (let i = 0; i < rows; i++) {
      data.push({
        email: emails[i],
        license:
          group.licenseLevels[i] != null
            ? group.licenseLevels[i].key
            : group.licenseLevels[0].key,
        role: planTypeLevel !== "gov" ? (i < 2 ? "Student" : "Teacher") : "",
      });
    }
    return data;
  }, [group, planTypeLevel]);

  useEffect(() => {
    if (templateData.length > 0 && triggerDownload) {
      console.log(templateRef.current);
      templateRef.current.link.click();
      setTriggerDownload(false);
    }
  }, [templateData, triggerDownload]);

  useEffect(() => {
    refreshTimer.current = setInterval(() => {
      getGroupDomains();
    }, 5 * 60 * 1000);
    return () => {
      clearInterval(refreshTimer.current);
      refreshTimer.current = null;
    };
  }, [getGroupDomains]);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack
        direction="column"
        pb={"50px"}
        sx={{
          "& .MuiDivider-root": {
            borderBottomWidth: "medium",
          },
        }}
      >
        <Stack direction="row" alignItems="center">
          <PageTitle>Add Users</PageTitle>
          <Stack
            direction="row"
            minHeight="100%"
            alignItems="center"
            justifyContent="center"
            pt={3}
            sx={{ flex: 1 }}
          >
            <Tooltip
              followCursor
              title="Download Communication Materials to help spread the word!"
            >
              <Button
                variant="outlined"
                startIcon={<DownloadOutlined fontSize="inherit" />}
                onClick={() => {
                  downloadFile(
                    "/support/group-code-materials.zip",
                    "Group Code Materials.zip"
                  );
                }}
              >
                Download Communication Materials
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        <Typography variant="body2">
          This is where you can add users to your Group Plan using multiple
          methods.
        </Typography>
        <Divider />
        <Typography id="add-user" variant="h4">
          Option 1: Invite Individual Users{" "}
          <FeatureHelpVideo
            videoId="1043472822"
            title="Tip: How to add individual users"
            showText
          />
        </Typography>
        <Stack pl={3}>
          <Typography>
            If you invite existing Sooth users, they will receive an email
            notification they are now part of your Group Plan. No further action
            is required.
          </Typography>
          <Typography>
            If you invite new users who do not currently have an account on
            Sooth, they will receive an email notification but need to complete
            the sign-up process by following the instructions in the email. If
            students do not receive or regularly check their email, you will
            need to guide them to{" "}
            <Link to="/signup">https://app.sooth.fyi/signup</Link> to complete
            the process.
          </Typography>
          <Box component="ul" my={0}>
            <Typography component="li">
              <Button variant="text" onClick={() => setEmailDialog(true)}>
                What does the email look like?
              </Button>
            </Typography>
            <Typography component="li">
              Check out the downloadable materials above to help spread the
              word.
            </Typography>
          </Box>
          <Box>
            <Button onClick={() => setAddUserModal(true)}>Add User</Button>
          </Box>
          {addUserModal && (
            <AddGroupUserModal
              open={addUserModal}
              handleClose={handleAddUserClose}
            />
          )}
        </Stack>
        {emailDialog && (
          <Dialog
            open={emailDialog}
            fullWidth
            maxWidth={isMobile ? "xl" : "md"}
            onClose={() => setEmailDialog(false)}
          >
            <DialogTitle
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>Example Email</Box>
              <IconButton edge="end" onClick={() => setEmailDialog(false)}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src="/images/New-User-Group-Invite.png"
                alt="Example Email sent to invited users"
                sx={{
                  display: "block",
                  maxWidth: "100%",
                  height: "auto",
                  aspectRatio: "0.94148",
                }}
              />
            </DialogContent>
          </Dialog>
        )}
        <Divider />
        <Typography id="bulk-upload" variant="h4">
          Option 2: Bulk User Upload{" "}
          <FeatureHelpVideo
            videoId="1043472478"
            title="Tip: How to bulk upload users"
            showText
          />
        </Typography>
        <Stack pl={3}>
          <Typography>
            You can bulk upload your users using a .csv file. The file must have
            three columns: email, license, role. Note column names are case
            sensitive.
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle1">File Format Guidance:</Typography>
            <Box>
              <Button
                variant="text"
                startIcon={<DownloadOutlined fontSize="inherit" />}
                onClick={() => setTriggerDownload(true)}
              >
                Download Template
              </Button>
              <CSVLink
                data={templateData}
                filename="sooth-bulk-user-upload-template.csv"
                className="hidden"
                ref={templateRef}
                target="_blank"
                headers={[
                  { label: "email", key: "email" },
                  { label: "license", key: "license" },
                  { label: "role", key: "role" },
                ]}
              />
            </Box>
          </Stack>
          <TableContainer>
            <Table
              size="small"
              sx={{
                width: "auto",
                maxWidth: "100%",
              }}
            >
              {planTypeLevel === "k12" && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ borderBottom: "none" }}>
                        K-12 Group Plan
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Column Name</TableCell>
                      <TableCell>Column Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>email</TableCell>
                      <TableCell>User's Email</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>license</TableCell>
                      <TableCell>
                        Based on your Group Plan licenses.
                        <br />
                        Assign each user to one of the following:
                        <br />
                        {licenseLevels}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>role</TableCell>
                      <TableCell>"Student" or "Teacher"</TableCell>
                    </TableRow>
                  </TableBody>
                </Fragment>
              )}
              {planTypeLevel === "highed" && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ borderBottom: "none" }}>
                        Higher Education Group Plan
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Column Name</TableCell>
                      <TableCell>Column Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>email</TableCell>
                      <TableCell>User's Email</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>license</TableCell>
                      <TableCell>
                        Based on your Group Plan licenses. Assign each user
                        "group-highed".
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>role</TableCell>
                      <TableCell>"Student" or "Teacher"</TableCell>
                    </TableRow>
                  </TableBody>
                </Fragment>
              )}
              {planTypeLevel === "gov" && (
                <Fragment>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ borderBottom: "none" }}>
                        Gov/Research Group Plan
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Column Name</TableCell>
                      <TableCell>Column Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>email</TableCell>
                      <TableCell>User's Email</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>license</TableCell>
                      <TableCell>
                        Based on your Group Plan licenses. Assign each user
                        "group-gov".
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>role</TableCell>
                      <TableCell>Leave blank.</TableCell>
                    </TableRow>
                  </TableBody>
                </Fragment>
              )}
            </Table>
          </TableContainer>
          <Typography variant="subtitle1" fontWeight="bold">What happens next?</Typography>
          <Typography>
            If you upload existing users, they will receive an email
            notification they are now part of your Group Plan. No further action
            is required.
          </Typography>
          <Typography>
            If you upload new users, they will receive an email notification but
            will need to complete the sign-up process by following the
            instructions in the email they receive. If students do not receive or
            regularly check their email, you will need to guide them to{" "}
            <Link to="/signup">https://app.sooth.fyi/signup</Link> to complete
            the process.
          </Typography>
          <Box component="ul" my={0}>
            <Typography component="li">
              <Button variant="text" onClick={() => setEmailDialog(true)}>
                What does the email look like?
              </Button>
            </Typography>
            <Typography component="li">
              Check out the downloadable materials above to help spread the
              word.
            </Typography>
          </Box>
          <Box>
            <Button onClick={() => setBulkUploadModal(true)}>
              Bulk Upload
            </Button>
          </Box>
          {bulkUploadModal && (
            <GroupUserBulkUpload
              open={bulkUploadModal}
              handleClose={() => setBulkUploadModal(false)}
            />
          )}
        </Stack>
        <Divider />
        <Typography id="group-codes" variant="h4">
          Option 3: Group Codes{" "}
          <FeatureHelpVideo
            showText
            videoId="1016760803"
            title="Tip: How to use Group Codes"
          />
        </Typography>
        <Stack pl={3}>
          <Typography>
            Group Codes are an easy way to let users join your Group. You can
            generate a new code and give it out to individuals you want to join
            your Group.
          </Typography>
          <Typography>
            This method of adding users to your Group offers flexibility,
            allowing you to control access by individual classes or manage user
            cycles from one semester to the next, keeping you in the driver’s
            seat.
          </Typography>
          <Typography>
            All users will be added to the "Student" role when they join. You
            can adjust this later in{" "}
            <Link to="user-management">User Management</Link>.
          </Typography>
          <Typography>
            Deleting a Group Code will not delete users who have joined your
            Group with that code. It will, however, prevent anyone using that
            code from joining your Group in the future.
          </Typography>
          <Typography>
            Check out the download materials above to help spread the word.
          </Typography>
          {!isGroupLoading && (
            <Fragment>
              <Box>
                <Button
                  startIcon={<AddOutlined fontSize="inherit" />}
                  onClick={handleCreateGroupCode}
                  disabled={isGroupControlsLoading}
                >
                  Create Group Code
                </Button>
              </Box>
              <List
                sx={{
                  pr: "80%",
                  "> li:not(:last-child)": {
                    borderBottom: `1px solid ${palette.divider}`,
                  },
                }}
              >
                {groupCodes.map((groupCode) => (
                  <GroupCodeListItem
                    groupCode={groupCode}
                    key={`group-code-${groupId}-${groupCode.id}`}
                  />
                ))}
              </List>
            </Fragment>
          )}
        </Stack>
        <Divider />
        <Typography id="auto-join-domains" variant="h4">
          Option 4: Domain Auto-join{" "}
          <FeatureHelpVideo
            showText
            videoId="1016584719"
            title="Tip: How to use Domain Auto-join"
          />
        </Typography>
        <Stack pl={3}>
          <Typography>
            This feature allows you to seamlessly populate your Group with all
            your intended users. We use your Group’s unique email domain(s)
            (e.g. @abcschool.org) to identify users and assign them to your
            Group when they log in, including future users who don’t yet have an
            account. It’s easy to set up and all the magic happens in the
            background.
          </Typography>
          <Alert severity="warning">
            <Typography>
              This feature is only appropriate for your Group{" "}
              <Typography component="span" sx={{ textDecoration: "underline" }}>
                if the following statement is true
              </Typography>
              :
            </Typography>
            <Box component="ul" mb={2}>
              <Typography component="li">
                You have purchased a Group license that is intended to include
                everyone who shares the same email domain(s) (e.g.
                abcschool.org).
              </Typography>
            </Box>
            <Typography>
              If the above statement is{" "}
              <Typography component="span" sx={{ textDecoration: "underline" }}>
                not true
              </Typography>{" "}
              and there are large groups of people (relative to the size of your
              Group Subscription) who also use the same email domain (e.g.
              abcschool.org), you run the risk of extending licenses to users
              beyond your intended Group. Please use Group Codes or Bulk Upload
              instead.
            </Typography>
          </Alert>
          <Alert severity="warning">
            <Typography>
              Adding an domain will require coordination with your IT
              department. Click "+ Add Domain" below to get started and follow
              the instructions by clicking "How to Verify" once added. Each
              domain you include will have a unique verification code. These
              steps are designed to protect your organization and the integrity
              of your Group Plan.
            </Typography>
          </Alert>
          <Typography>
            Deleting a domain will not remove users from your Group. It will,
            however, no longer auto-join users with that domain to your Group in
            the future.
          </Typography>
          <Alert severity="info">
            <Typography>
              Include all email domains relevant to your organization. Your
              school may have different domains for staff and students (e.g.
              "student.abcschool.org" for students and "abcschool.org" for
              staff).
            </Typography>
            <Typography>
              Include all Single Sign-On email domains if these are different
              than your regular email domains (e.g. "abcschool.net").
            </Typography>
            <Typography>
              Each unique domain must be added separately.
            </Typography>
          </Alert>
          <Stack direction="row">
            <Button
              startIcon={<AddOutlined fontSize="inherit" />}
              onClick={() => setShowNew(true)}
              disabled={showNew || isGroupControlsLoading}
            >
              Add Domain
            </Button>
            <IconButton
              onClick={() => getGroupDomains()}
              disabled={isGroupControlsLoading}
            >
              <Tooltip title="Refresh Domains">
                <RefreshOutlined fontSize="inherit" />
              </Tooltip>
            </IconButton>
          </Stack>
          <List
            sx={{
              pr: { xs: "100%", md: "35%" },
              "> li:not(:last-child)": {
                borderBottom: `1px solid ${palette.divider}`,
              },
            }}
          >
            {showNew && (
              <AutoJoinDomain
                groupDomain={
                  new GroupDomain({
                    domain: "",
                    isVerified: false,
                  })
                }
                isNewItem
                closeNew={() => setShowNew(false)}
                setVerifyModal={() => {}}
              />
            )}
            {map(orderBy(groupDomains, ["domain"]), (daj, index) => (
              <AutoJoinDomain
                groupDomain={daj}
                setVerifyModal={setVerifyModal}
                key={daj.id != null ? daj.id : `new-${index}`}
              />
            ))}
          </List>
        </Stack>
        {verifyModal != null && (
          <Dialog
            open={verifyModal != null}
            onClose={() => setVerifyModal(null)}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>How to verify {verifyModal.domain}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                In order to verify your domain, follow the steps below:
              </DialogContentText>
              <List sx={{ listStyle: "decimal", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText
                    disableTypography
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    Copy your verification code:{" "}
                    <Button
                      variant="text"
                      endIcon={<CopyAllOutlined fontSize="inherit" />}
                      size="small"
                      sx={({ typography }) => ({
                        ...typography.body1,
                      })}
                      onClick={() =>
                        handleCopyVerificationCode(verifyModal.verificationCode)
                      }
                    >
                      {verifyModal.verificationCode}
                    </Button>
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Access the DNS records for {verifyModal.domain}.
                  </ListItemText>
                  <Alert severity="warning">
                    <Typography>
                      Accessing your domain's DNS records may require a domain
                      administrator from your IT support to assist you in the
                      following steps.
                    </Typography>
                  </Alert>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Create a TXT record for your domain with a name of
                    '_sooth_verify'.
                  </ListItemText>
                  <ListItemText>
                    Example: Your full TXT record name should look like
                    _sooth_verify.
                    {verifyModal.domain} when saved.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Set the value of the TXT record to the verification code you
                    copied above.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Set the TTL (Time to live) value of the TXT record to 60
                    seconds.
                  </ListItemText>
                </ListItem>
              </List>
              <Alert severity="info">
                <Typography>
                  We check for your verification code every 30 minutes. However,
                  DNS records can take up to 48 hours to propagate. Your
                  verification status will be updated as soon as we can validate
                  the verification code in your DNS records. Please check back
                  on the Add Users page to see if your domain has been
                  verified.
                </Typography>
              </Alert>
            </DialogContent>
            <DialogActions
              sx={({ palette }) => ({
                backgroundColor: palette.secondary.main,
              })}
            >
              <Button onClick={() => setVerifyModal(null)}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
      </Stack>
      <Footer />
    </Box>
  );
}
