import { axiosInstance } from "lib/axios"
import { searchAPI } from "./apis.service"

export const getRefreshToken = async (url) => {
    const { data } = await axiosInstance.post(searchAPI.getWashingtonRefreshToken, {
        articleUrl: url
    }, {
        cache: false
    })

    return data;
}