import React from "react";
import { Link } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Typography, Stack } from "@mui/material";
import { useQuickSearch } from "../hooks/use-quick-search.hook";
import { logEvent } from "services/amplitude.service";

const QuickSearchResults = () => {
  const { searchList, getQuickSearch } = useQuickSearch();

  const redirectPage = (url) => {
    return url.replace(
      "https://staging-app.sooth.fyi/search",
      "https://staging-app.sooth.fyi/"
    );
  };

  return (
    <Box
      sx={{
        minHeight: "55vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        py: "7.5rem",
        px: { xs: 3, md: "7.5rem" },
        backgroundColor: "background.paper",
        borderTopLeftRadius: "5rem",
        borderTopRightRadius: "5rem",
      }}
    >
      {searchList.length ? (
        <Box
          sx={{
            backgroundColor: "secondary.main",
            p: "5rem",
            borderRadius: "10px",
            color: "text.primary",
            width: { xs: "100%", md: "75rem" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: "2rem" }}
          >
            <Typography variant="h6" color="text.secondary">
              Quick Search
            </Typography>
            <RefreshIcon
              sx={{
                fontSize: "2rem",
                color: "text.secondary",
                cursor: "pointer",
              }}
              onClick={getQuickSearch}
            />
          </Stack>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)" },
              gap: "2rem",
            }}
          >
            {searchList.map((item) => (
              <Link
                to={item.link_chr}
                key={item.quickSearch_chr}
                onClick={() =>
                {
                  logEvent(`click_curious_query`, {
                    quicksearch: item.link_chr,
                  });
                }
                }
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {item.quickSearch_chr}
              </Link>
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default QuickSearchResults;
