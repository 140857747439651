export const categoryDescriptions = {
  "Global News":
    "Sooth’s Global News category includes major print and online news and information outlets covering national and international news with a strong emphasis on daily original reporting. The majority of Sooth’s Global News sources have been rated by NewsGuard. Global News does not include any sources with a Trust Score below 60 (out of 100), per NewsGuard’s rating system.",

  "Other Selected Sources":
    "Sooth’s Other Selected Sources category includes a wide range of sources that do not adequately fit into one of the other categories. These include consulting firms, financial institutions, trade associations, and many other types of organizations.",

  "Think Tanks":
    "Sooth’s Think Tanks category includes leading national and international think tanks covering a broad range of topics. The majority of all Think Tanks have been ranked by the Think Tanks and Civil Societies Program at the Lauder Institute, University of Pennsylvania. Think tanks, as defined by the Lauder Institute, are public policy research and engagement organizations that generate policy-oriented research, analysis, and advice on domestic and international issues that enable the public and policymakers to make informed decisions regarding public policy. Think tanks may be affiliated or independent institutions and are structured as permanent bodies.",

  "Periodicals & Specialty News":
    "Sooth’s Periodicals & Specialty News category includes daily, weekly, and monthly print and online news and information outlets covering both broad news topics and industry-specific news with a strong emphasis on original reporting. The majority of Sooth’s Periodicals & Specialty News sources have been rated by NewsGuard. Periodicals & Specialty News does not include any sources with a Trust Score below 60 (out of 100), per NewsGuard’s rating system.",

  "Intergovernmental Organizations":
    "Sooth’s Intergovernmental Organizations (IGO) category includes organizations whose members are composed primarily of sovereign states.",

  "US Government":
    "Sooth’s U.S. Government category includes U.S. federal government websites and official state sites.",

  "Academia & Scholarly Resources":
    "Sooth’s Academia category includes leading U.S. and non-U.S. colleges and universities and their affiliated research centers and publications. This category also includes numerous online research repositories.",
  Books: "Books",
  "Encyclopedia & Dictionary": "Encyclopedia & Dictionary",
  CORE: "These search results are exclusively from CORE. This database is a not-for-profit research tool that currently contains 287 million open access articles collected from 11,000 data providers globally. CORE indexes content from repositories, which do not peer-review their content, and journals, which do peer-review their content.  CORE does not own any rights to its aggregated content and each resource has its own license. Learn more here: https://core.ac.uk/ ",
  "Semantic Scholar":
    "These search results are exclusively from Semantic Scholar. This database is a free, AI-powered research tool for scientific literature, based at the Allen Institute for AI. It is comprised of 217,150,700 papers from all fields of science, most of which are open access. Semantic Scholar does not peer-review its papers. Semantic Scholar is licensed through the Allen Institute for Artificial Intelligence (AI2). Learn more here: https://www.semanticscholar.org/",
  Arxiv:
    "These search results are exclusively from arXiv. This database is a free, open-access archive for nearly 2.4 million scholarly articles in the fields of physics, mathematics, computer science, quantitative biology, quantitative finance, statistics, electrical engineering and systems science, and economics. Materials on this site are not peer-reviewed, however, they do go through a moderation process performed by arXiv. arXiv allows publishers to select Creative Commons licenses that fit their distribution preferences. Learn more here: https://arxiv.org/ ",
  "Springer Nature":
    "These search results are exclusively from Springer Nature. This database is comprised of over 7 million articles from over 3,000 journals. Of these, over 1.25 million articles and 693 journals are open access. Springer Nature publishes across three platforms:  1) SpringerLink.com, which features peer-reviewed articles from 3,750 journals in all disciplines, including science, technology, medicine, and social sciences; 2) Nature.com, which accepts peer-reviewed articles in 145 journals that cover science, technology, and medicine; and 3) BioMedCentral.com, which provides access to over 13,000 open access documents from 290 peer-reviewed journals in biology, clinical medicine, and health. Springer Nature allows publishers to select licenses that fit their distribution preferences. Learn more here: https://www.springernature.com/gp",
  PLOS: "These search results are exclusively from PLOS One (Public Library of Science.) This database is a non-profit, open access repository that publishes 14 peer-reviewed journals covering topics in science and medicine. PLOS One applies the Creative Commons Attribution license to its published works. Learn more  here: https://plos.org/",
  "peer-reviewed":
    "This category includes dozens of publishers who peer review all their journals, however, your search results are <b>not guaranteed</b> to be peer-reviewed because your search results may include Editorials, Commentaries, News, and Book Reviews which are <b>not</b> peer-reviewed. Please review your results carefully."
  };
