import { Card, Typography, useTheme, CardContent, Box, Tooltip } from "@mui/material";
import CategoryIcon from "features/News/components/CategoryIcon";
import { useLocation, useParams } from "react-router-dom";
import { ImInfo } from "react-icons/im";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { categoryDescriptions } from "data/category-description";
import PeerReviewedDetail from "features/News/components/peer-reviewed-detail";
import React from "react";

function TooltipContent({ category, isPeerReviewed }) {
  if (isPeerReviewed){
    return (
      <Typography
        variant="body1"
        component="div"
        dangerouslySetInnerHTML={{
          __html: categoryDescriptions["peer-reviewed"],
        }}
      />
    );


  }
    return (
      <Typography variant="body1">{categoryDescriptions[category]}</Typography>
    );
}

export const SidebarCard = ({ category, categoryType }) => {
  const theme = useTheme();
  

  const { academiaSearch } = useParams();
  const isMobile = useIsMobile();
  const {pathname} = useLocation();
  const isPeerReviewed = pathname.includes("/peer-reviewed");
  
  const getLabel = () => {
   let label;

   switch (categoryType) {
     case null:
       label = category;
       break;
     case "wiki":
       label = "Encyclopedia & Dictionary";
       break;
     case "peer-reviewed":
       label = "Peer Reviewed Sources";
       break;
     default:
       label = "Books";
   }


    if (academiaSearch) {
      label = academiaSearch;
    }
    return label;
  };

  const handleInfoClick = () => {};

  const style={
    ...(isPeerReviewed && {
      maxHeight: "30vh",
      overflowY: "auto",
    }),
  }

  return (
    <Card
      elevation={0}
      sx={{
        position: "relative",
        backgroundColor: theme.palette.secondary.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        wordBreak: "break-word",
        minHeight: { xs: "20vh", sm: "25vh" },
        ...style,
      }}
    >
      <CategoryIcon
        iconId={category}
        sx={{
          fill: theme.palette.secondary.dark,
          position: "absolute",
          fontSize: "30rem",
          right: "-10rem",
          bottom: "-10rem",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 4.4,
          right: 5.4,
          display: "flex",
          zIndex: 10,
          "& > svg": {
            color: theme.palette.secondary.dark,
          },
          "&:hover > svg": {
            color: theme.palette.primary.dark,
          },
        }}
      >
        <Tooltip
          title={
            !isMobile ? (
              <TooltipContent
                isPeerReviewed={isPeerReviewed}
                category={getLabel()}
              />
            ) : null
          }
          placement="right"
          arrow
        >
          <Box>
            <ImInfo
              onClick={handleInfoClick}
              size={16}
              style={{
                p: 1,
              }}
            />
          </Box>
        </Tooltip>
      </Box>
      <CardContent>
        <Typography
          component="div"
          key={category}
          fontSize="2.3rem"
          color="primary.dark"
          fontFamily={theme.typography.h3.fontFamily}
          fontWeight={theme.typography.h3.fontWeight}
        >
          {getLabel()}
          {isPeerReviewed && (
            <Typography mt={1} variant="body2" color="primary.dark">
              These publishers peer review all their <b>journals</b>, however,
              your search results are <b>not guaranteed</b> to be peer-reviewed.
              Your results may include Editorials, Commentaries, News, and Book
              Reviews which are <b>not</b> peer-reviewed. Review your results
              carefully.
            </Typography>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};
