import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";
import useLocalStorage from "@rehooks/local-storage";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const PeerReviewMessageModal = () => {
  const { user } = useSelector((state) => state.auth);
  const [dontShowPopup, setDontShowPopup] = useLocalStorage(
    `${user.userId}-peerreviewpopup`,
    true
  );
  const [open, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const { pathname } = useLocation();
  const isPeerReviewed = pathname.includes("/peer-reviewed");

  const handleClose = () => {
    if (checked) {
      setDontShowPopup(false);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (dontShowPopup && isPeerReviewed) {
      setIsOpen(true);
    }
  }, [dontShowPopup]);

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ textAlign: "center", fontSize: "2.3rem" }}>
        Attention
      </DialogTitle>
      <Stack mx={2} mb={2}>
        <DialogContentText>
          Results may include Editorials, Commentaries, News, and Book Reviews
          which are <b>not</b> peer-reviewed. Review your results carefully.
        </DialogContentText>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked((check) => !check)}
              />
            }
            label="Do not show this message again "
          />
        </FormGroup>
      </Stack>
      <DialogActions
        sx={{
          backgroundColor: "secondary.main",
          display: "flex",
          justifyContent: "end",
          px: 5,
          py: 3,
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            width: "50%",
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PeerReviewMessageModal;
