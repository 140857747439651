import { setIsSidebarOpen } from "context/features/dataSlice";
import { setIsSourceSidebarOpen } from "context/features/dataSlice";
import CategoryNews from "features/CategoryNews";
import BookmarkDrawer from "features/Bookmarks";
import SourceSidebar from "features/SourceInfo";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SearchLayout from "components/layout/SearchLayout";
import PeerReviewMessageModal from "features/CategoryNews/component/popup/PeerReviewMessageModal";

const NewsCategoryPage = () => {
  const { isSidebarOpen } = useSelector((state) => state.data);
  const { isSourceSidebarOpen } = useSelector((state) => state.data);
  const [scrollRef, setScrollRef] = useState(null);
  const dispatch = useDispatch();

  const sidebarClosedHandler = () => {
    dispatch(setIsSidebarOpen(false));
    document.body.style.overflow = "unset";
  };

  const sourceSidebarClosedHandler = () => {
    dispatch(setIsSourceSidebarOpen(false));
    document.body.style.overflow = "unset";
  };

  const showSidebar = () => {
    dispatch(setIsSidebarOpen(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const showSourceSidebar = () => {
    dispatch(setIsSourceSidebarOpen(true));

    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <React.Fragment>
      <SearchLayout>
        <CategoryNews />
        <PeerReviewMessageModal />
      </SearchLayout>
      <BookmarkDrawer
        isSidebarOpen={isSidebarOpen}
        sidebarClosedHandler={sidebarClosedHandler}
        showSidebar={showSidebar}
      />
      <SourceSidebar
        isSourceSidebarOpen={isSourceSidebarOpen}
        sourceSidebarClosedHandler={sourceSidebarClosedHandler}
        showSourceSidebar={showSourceSidebar}
      />
    </React.Fragment>
  );
};

export default NewsCategoryPage;
