import { axiosInstance } from "lib/axios"
import { searchAPI } from "services/apis.service";

export const fetchReadingLevel = async (requestBody) => {
    console.log(searchAPI.readingLevel);
    
    const { data } = await axiosInstance.post(
      "https://openai.sooth.fyi/api/articleCompLevel",
      requestBody,{
        cache: false
      }
    );

    return data;
}