import { find } from "lodash";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import dayjs from "lib/dayjs";

import {
  setCurrentCategory,
  setCurrentSearchTerm,
  setCurrentSource,
  setCurrentSubCategory,
  setCurrentTimeframe,
  setEndDate,
  setIsActive,
  setSourceCategories,
  setStartDate,
} from "../context";
import {
  biasCategories,
  categories,
  frequencies,
  sources,
} from "../data/search-fields.data";

const defaultSource = find(sources, { default: true });
const defaultTimeframe = find(frequencies, { default: true });

export function useSourceSearch() {
  const {
    currentCategory,
    currentSearchTerm,
    currentSource,
    currentSubCategory,
    currentTimeframe,
    endDate,
    isActive,
    sourceCategories,
    subCategories,
    startDate,
  } = useSelector((state) => state.search);

  const { search, source, category, academiaSearch } = useParams();
  let [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const peerreviewed = pathname.includes("/peer-reviewed"); 

  function handleActiveStateChange(state) {
    dispatch(setIsActive(state));
  }

  function handleSourceCategoryChange(source) {
    if (source === "rating") {
      return biasCategories;
    } else {
      return categories;
    }
  }

  function getSource(key) {
    return find(sources, { key });
  }

  function getCategory(key) {
    if (currentSource === "rating") {
      return find(biasCategories, { key });
    }
    return find(categories, { key });
  }

  function getTimeframe(key) {
    return find(frequencies, { key });
  }

  function getSubCategory(key) {
    return find(subCategories, { key });
  }

  function buildSearchUrl({
    query,
    source = defaultSource.key,
    category = null,
    date = defaultTimeframe.key,
    subCategory = null,
    endDate = null,
    startDate = null,
  } = {}) {
    if (date == null || source == null) return;
    let filterQuery = "";
    if (date !== defaultTimeframe.key) {
      const timeframe = find(frequencies, { key: date });
      filterQuery = `?pubDate=${timeframe.label}`;
      if (timeframe.key === "custom-date") {
        const end = dayjs(endDate).format("MMM DD, YYYY");
        const start = dayjs(startDate).format("MMM DD, YYYY");
        filterQuery = `${filterQuery}&dateRange=${start} - ${end}`;
      }
    }
    const encodedQuery = encodeURI(decodeURI(query));

    if (encodedQuery === "") return;

    if (source === "categories") {
      if (category) {
        const catObj = find(categories, { key: category });
        const subCatObj = find(subCategories, { key: subCategory });
        return `/search/${encodedQuery}/${source}/${catObj.label}${
          subCatObj ? `/${subCatObj.label}` : ""
        }${filterQuery}`;
      } else {
        return `/search/${encodedQuery}${filterQuery}`;
      }
    } else if (source === "rating") {
      if (category) {
        const categoryLabel = find(biasCategories, { key: category }).label;
        return `/search/${encodedQuery}/${source}/${categoryLabel}${filterQuery}`;
      } else {
        return `/search/${encodedQuery}/${source}${filterQuery}`;
      }
    } else {
      return `/search/${encodedQuery}/${source}${filterQuery}`;
    }
  }
  useEffect(() => {
    if (search == null) {
      dispatch(setCurrentSearchTerm(""));
    } else {
      dispatch(setCurrentSearchTerm(search));
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (source == null) {
      dispatch(setCurrentSource(defaultSource.key));
      dispatch(setSourceCategories(categories));
    } else if (source === "rating") {
      dispatch(setCurrentSource(source));
      dispatch(setSourceCategories(biasCategories));
    } else {
      dispatch(setCurrentSource(source));
      dispatch(setSourceCategories(categories));
    }
  }, [source, dispatch]);

  useEffect(() => {
    if (category != null) {
      const currentCategory = find(sourceCategories, { label: category });
      if (currentCategory != null) {
        if (currentCategory.key === "academia") {
          const subCategory = find(subCategories, { label: academiaSearch });
          if (subCategory != null) {
            dispatch(setCurrentSubCategory(subCategory.key));
          } else {
            dispatch(setCurrentSubCategory(null));
          }
          dispatch(setCurrentCategory(currentCategory.key));
        } else {
          dispatch(setCurrentCategory(currentCategory.key));
          dispatch(setCurrentSubCategory(null));
        }
      } else {
        dispatch(setCurrentCategory(null));
        dispatch(setCurrentSubCategory(null));
      }
    } else {
      dispatch(setCurrentCategory(null));
      dispatch(setCurrentSubCategory(null));
    }
  }, [category, academiaSearch, sourceCategories, subCategories, dispatch]);

  useEffect(() => {
    const pubDate = searchParams.get("pubDate");
    const dateRange = searchParams.get("dateRange");

    const filteredTimeframe = find(frequencies, { label: pubDate });
    if (filteredTimeframe != null) {
      dispatch(setCurrentTimeframe(filteredTimeframe.key));
      if (
        filteredTimeframe.key === "custom-date" &&
        searchParams.has("dateRange")
      ) {
        const range = dateRange.split(" - ");
        dispatch(setStartDate(dayjs(range[0])));
        dispatch(setEndDate(dayjs(range[1])));
      }
    } else {
      dispatch(setCurrentTimeframe(defaultTimeframe.key));
      dispatch(setEndDate(null));
      dispatch(setStartDate(null));
    }
  }, [searchParams, dispatch]);

  return {
    buildSearchUrl,
    currentCategory,
    currentSearchTerm,
    currentSource,
    currentSubCategory,
    currentTimeframe,
    defaultSource,
    defaultTimeframe,
    endDate,
    getCategory,
    getSource,
    getSubCategory,
    getTimeframe,
    handleActiveStateChange,
    handleSourceCategoryChange,
    isActive,
    sources,
    sourceCategories,
    startDate,
    subCategories,
    timeframes: frequencies,
    peerreviewed,
  };
}
