import {
  useTheme,
  List,
  ListItem,
  ListItemText,
  Box,
  Card,
  Typography,
} from "@mui/material";
import NewsguardCriteria from "features/SourceInfo/components/NewsGuardCriteria";
import { useIsLargeScreen } from "hooks/is-large-screen";
import React from "react";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const PeerReviewedDetail = ({ isSidebar=true }) => {
  const {pathname} = useLocation();
  const theme = useTheme();
  const isPeerReviewed = pathname.includes("/peer-reviewed");
  const largeScreen = useIsLargeScreen();

  const sx = { color: !isSidebar ? "text.primary" : "primary.dark" };

  if (!isPeerReviewed){
    return <Box></Box>;
  }
    return (
      <Card
        elevation={0}
        sx={{
          position: "relative",
          backgroundColor: theme.palette.secondary.main,
          display: "flex",
          flexDirection: "column",
          wordBreak: "break-word",
          mt: 2,
          gap: 1,
          p: 2,
          maxHeight: largeScreen ? "unset" : "35vh",
          overflowY: "auto",
        }}
      >
        <Typography fontWeight="bold" variant="body1" color="primary.dark">
          How do I know if it’s been peer reviewed?
        </Typography>
        <Typography variant="body2" color="primary.dark">
          Peer reviewed articles and research papers should include the
          following:
        </Typography>
        <NewsguardCriteria sx={sx} value="Yes" criteria="Abstract " />
        <NewsguardCriteria
          sx={sx}
          value="Yes"
          criteria="Citations / bibliography  "
        />
        <NewsguardCriteria
          sx={sx}
          value="Yes"
          criteria="Longer than a few pages  "
        />
        <NewsguardCriteria
          sx={sx}
          value="Yes"
          criteria="Disclosure about the peer review process  "
        />
        <Box sx={{ display: "flex", gap: 1 }}>
          <CloseIcon sx={{ color: "red", height: 18, width: 18 }} />
          <Typography sx={sx} variant="body2">
            Editorials, Commentaries, Opinions, News, and Book Reviews are
            generally <b>not</b> peer reviewed
          </Typography>
        </Box>
      </Card>
    );
};

export default PeerReviewedDetail;
