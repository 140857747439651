import { filter, find } from "lodash";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  DataGrid,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridActionsCellItem,
  gridClasses,
} from "@mui/x-data-grid";
import { Box, Chip, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import {
  GroupAddOutlined,
  PersonOffOutlined,
  PersonOutlined,
  ShieldOutlined,
  SyncOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";

import { getFormattedDate } from "utils/time.util";

import { useAdmin } from "../hooks";
import { filterRoles, roles } from "../data/roles";
import { DEFAULT_SORT_MODEL } from "../context";
import SetupGroup from "./SetupGroup";

const initialState = {
  sorting: {
    sortModel: [...DEFAULT_SORT_MODEL],
  },
};

export default function AdminUserTable({ sx = {} }) {
  const { palette, typography } = useTheme();
  const {
    filterModel,
    impersonateUser,
    isAdminLoading,
    paginationModel,
    setFilterModel,
    setPaginationModel,
    setSortModel,
    sortModel,
    totalSize,
    updateUserStatus,
    users,
  } = useAdmin();

  const { user } = useSelector((state) => state.auth);

  const [groupUser, setGroupUser] = useState(null);

  const gridSx = useMemo(
    () => ({
      border: "none",
      maxWidth: "100vw",
      [`& .${gridClasses.cell}:not(.${gridClasses["cell--editable"]}):focus, & .${gridClasses.cell}:not(.${gridClasses["cell--editable"]}):focus-within`]:
        {
          outline: "none",
        },
      [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
        {
          outline: "none",
        },
      [`& .${gridClasses.cell}.${gridClasses["cell--editable"]}:hover`]: {
        outlineStyle: "solid",
        outlineColor: palette.primary.main,
        outlineWidth: "1px",
        outlineOffset: "-1px",
      },
    }),
    [palette]
  );
  const columns = [
    {
      field: "email",
      filterOperators: filter(
        getGridStringOperators(),
        ({ value }) =>
          value !== "isAnyOf" && value !== "isNotEmpty" && value !== "isEmpty"
      ),
      renderCell({ api, id, value }) {
        const { emailConfirmed } = api.getRow(id);
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            maxWidth="100%"
            minWidth="100%"
            minHeight="100%"
            gap={0}
          >
            <Tooltip followCursor title={value}>
              <Typography
                variant="caption"
                noWrap
                sx={{
                  flex: 11,
                  mr: "3px",
                }}
              >
                {value}
              </Typography>
            </Tooltip>
            {emailConfirmed ? (
              <Tooltip title="verified">
                <VerifiedUserOutlined
                  sx={{
                    flex: 1,
                    color: palette.grey[500],
                    fontSize: typography.body1.fontSize,
                    "&:hover": {
                      color: palette.success.main,
                    },
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="unverified">
                <ShieldOutlined
                  sx={{
                    flex: 1,
                    color: palette.grey[500],
                    fontSize: typography.body1.fontSize,
                    "&:hover": {
                      color: palette.warning.main,
                    },
                  }}
                />
              </Tooltip>
            )}
          </Stack>
        );
      },

      flex: 3,
      headerName: "Email",
      type: "string",
    },
    {
      field: "userName",
      filterOperators: filter(
        getGridStringOperators(),
        ({ value }) =>
          value !== "isAnyOf" && value !== "isNotEmpty" && value !== "isEmpty"
      ),
      flex: 3,
      headerName: "Username",
      type: "string",
      renderCell({ id, value }) {
        return id === user.userId ? (
          <Stack direction="row" alignItems="center" maxWidth="100%">
            <Tooltip followCursor title={`@${value}`}>
              <Typography variant="caption" noWrap sx={{ flex: 11 }}>
                @{value}
              </Typography>
            </Tooltip>
            <Chip size="small" color="info" variant="outlined" label="You" />
          </Stack>
        ) : (
          <Tooltip followCursor title={`@${value}`}>
            <Typography variant="caption" maxWidth="100%" noWrap>
              @{value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      filterable: false,
      field: "registerationDate",
      headerName: "Date Joined",
      flex: 1.5,
      valueFormatter(value) {
        return getFormattedDate(value);
      },
    },
    {
      filterable: false,
      field: "lastLoginTime",
      headerName: "Last Login",
      flex: 1.5,
      valueFormatter(value) {
        if (value == null) {
          return "never";
        }
        return getFormattedDate(value);
      },
    },
    {
      field: "roleName",
      headerName: "Role",
      type: "singleSelect",
      flex: 1.5,
      valueOptions: filterRoles.map((role) => ({
        value: role.key,
        label: role.label,
      })),
      filterOperators: filter(
        getGridSingleSelectOperators(),
        ({ value }) => value !== "isAnyOf"
      ),
      renderCell(params) {
        const { isInGroup } = params.row;

        const getRole = (value) => {
          const role = roles.find((role) => role.key === value);

          if (!role) {
            return isInGroup ? `Group: ${value}` : "Unknown";
          }

          return role.label;
        };

        const role = getRole(params.value);
        return  <Tooltip followCursor title={role}>
          {role}
          </Tooltip>
       
      },
    },
    {
      field: "isDeleted",
      flex: 1,
      headerName: "Status",
      renderCell({ value }) {
        if (value) {
          return "Inactive";
        }
        return "Active";
      },
      type: "boolean",
      filterable: true,
    },
    {
      field: "actions",
      flex: 0.1,
      getActions({ id, row }) {
        return [
          <GridActionsCellItem
            icon={<GroupAddOutlined fontSize="inherit" />}
            label="Setup Group"
            showInMenu
            disabled={id === user.userId || row.isInGroup || row.isDeleted}
            onClick={() => {
              setGroupUser(row);
            }}
          />,
          <GridActionsCellItem
            icon={<SyncOutlined fontSize="inherit" />}
            label="Impersonate"
            showInMenu
            disabled={id === user.userId || row.isDeleted}
            onClick={() => {
              impersonateUser(row);
            }}
          />,
          <GridActionsCellItem
            icon={
              row.isDeleted ? (
                <PersonOutlined fontSize="inherit" />
              ) : (
                <PersonOffOutlined fontSize="inherit" />
              )
            }
            label={row.isDeleted ? "Enable" : "Disable"}
            showInMenu
            disabled={id === user.userId}
            onClick={() => {
              updateUserStatus(row);
            }}
          />,
        ];
      },
      type: "actions",
    },
  ];

  return (
    <Box sx={{ ...sx }}>
      <DataGrid
        columns={columns}
        density="compact"
        disableRowSelectionOnClick
        filterMode="server"
        filterModel={filterModel}
        getRowId={(user) => user.id}
        initialState={initialState}
        loading={isAdminLoading}
        onFilterModelChange={setFilterModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        onSortModelChange={setSortModel}
        pageSizeOptions={[20, 40, 80]}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={totalSize}
        rows={users}
        sortingMode="server"
        sortModel={sortModel}
        sx={gridSx}
      />
      {groupUser != null && (
        <SetupGroup user={groupUser} handleClose={() => setGroupUser(null)} />
      )}
    </Box>
  );
}
