function logGAEvent(eventName, eventProperties = {}) {
  if (window.gtag && Number(process.env.REACT_APP_TRACK_GOOGLE_ANALYTICS)) {
    window.gtag("event", eventName, {
      event_category: eventName,
      event_label: eventName,
      ...eventProperties,
    });
  } else {
    console.warn("Google Analytics (gtag) is not initialized.");
  }
}

export { logGAEvent };